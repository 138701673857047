import { Dispatch } from "react";
import {
	IUserResponse,
	IUserState,
	LoginUserActionType,
	LogoutUserActionType,
	UserActionTypes,
} from "./user.types";

export const LoginUserAction =
	(payload: IUserResponse) => (dispatch: Dispatch<LoginUserActionType>) => {
		dispatch({
			type: UserActionTypes.LOGIN_USER,
			payload,
		});
	};

export const LogoutUserAction =
	() => (dispatch: Dispatch<LogoutUserActionType>) => {
		dispatch({
			type: UserActionTypes.LOGOUT_USER,
		});
	};
