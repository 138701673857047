import React from "react";
import {
	DeviceHealthStatusEnum,
	IReadingFoundItem,
} from "../models/reading.model";
export const DeviceInfoComp: React.FC<IReadingFoundItem> = ({
	carbonMonoxide,
	temperature,
	humidity,
	healthStatus,
	timestamp,
	serialNumber,
}) => {
	const stautsClass = (healthStatus: DeviceHealthStatusEnum) => {
		switch (healthStatus) {
			case DeviceHealthStatusEnum.Ok:
				return "is-success";
			case DeviceHealthStatusEnum.NeedsService:
				return "is-danger";
			case DeviceHealthStatusEnum.NeedsFilter:
				return "is-warning";
			default:
				return "";
		}
	};
	return (
		<tr>
			<th>{carbonMonoxide}</th>
			<td>{temperature}</td>
			<td>{humidity}</td>
			<td className={stautsClass(healthStatus)}>{healthStatus}</td>
			<td>{new Date(timestamp).toLocaleString()}</td>
		</tr>
	);
};
