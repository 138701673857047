import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { ErrorResponse, httpClient } from "../api/config/axios.config";
import { DeviceInfoComp } from "../components/DeviceInfo";
import { DashboardLayout } from "../layouts/DashboardLayout";
import { IDeviceInfoParams } from "../models/device.model";
import { IReadingFoundItem, IReadingResponse } from "../models/reading.model";
import { StoreType } from "../store";
import { IUserResponse } from "../store/actions/user.types";
import { Link } from "react-router-dom";
import { useLoading } from "../hooks/useLoading.hook";

export const DeviceInfoPage: React.FC = () => {
	const user = useSelector<StoreType, IUserResponse | undefined>(
		(state) => state.user.data
	);

	const [nextPage, setNextPage] = useState(1);
	const { loading, setLoading } = useLoading("done");
	const { serialNumber } = useParams<IDeviceInfoParams>();
	const location = useLocation();

	const [deviceData, setDeviceData] = useState<IReadingResponse>();

	const loadDevices = async () => {
		try {
			setLoading("loading");
			const { data } = await httpClient.get(
				`/reading/search?serialNumber=${serialNumber}&page=${nextPage}`,
				{
					headers: {
						Authorization: `Bearer ${user?.authToken}`,
					},
				}
			);
			setDeviceData(data as IReadingResponse);
			setLoading("done");
			console.log(data);
		} catch (e) {
			setLoading("failed");
			const err = e as ErrorResponse;
			alert(err?.response?.data?.message || "error loading data");
		}
	};

	useEffect(() => {
		loadDevices();
	}, [nextPage]);
	return (
		<DashboardLayout>
			{loading === "loading" && <span>loading...</span>}
			{loading === "failed" && (
				<span>error loading data, please try again</span>
			)}
			{loading === "done" && (
				<>
					<div className="level">
						<div className="level-right">
							<span className="subtitle">{serialNumber}</span>
						</div>
						<div className="level-left">
							<div className="buttons">
								<button className="button is-selected">7 days</button>
								<button className="button">14 days</button>
								<button className="button">30 days</button>
							</div>
						</div>
					</div>
					<br />
					<div className="columns is-centered">
						<table className="table is-fullwidth">
							<thead>
								<tr>
									<th>Carbon Monoxide</th>
									<th>Temperature</th>
									<th>Humidity</th>
									<th>Health</th>
									<th>Recorded on</th>
								</tr>
							</thead>

							<tbody>
								{deviceData?.foundItems.map((item) => (
									<DeviceInfoComp
										key={item.id}
										serialNumber={item.serialNumber}
										carbonMonoxide={item.carbonMonoxide}
										temperature={item.temperature}
										humidity={item.humidity}
										healthStatus={item.healthStatus}
										timestamp={item.timestamp}
										createdAt={item.createdAt}
										id={item.id}
									/>
								))}
							</tbody>
						</table>
					</div>
					{/* pagination section */}
					<div className="columns is-centered">
						<div className="column is-2">
							<div className="has-text-centered mb-5">
								<ul className="pagination-list">
									<li>
										{deviceData?.currentPage} of {deviceData?.totalPages}
									</li>
									<li>
										<button
											onClick={(e) =>
												deviceData?.currentPage &&
												setNextPage(deviceData?.currentPage - 1)
											}
											disabled={deviceData?.currentPage === 1}
											className="button pagination-link"
										>
											prev
										</button>
									</li>
									<li>
										<button
											onClick={(e) =>
												deviceData?.nextPage &&
												setNextPage(deviceData?.nextPage)
											}
											disabled={!deviceData?.nextPage}
											className="button pagination-link"
										>
											next
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</>
			)}
		</DashboardLayout>
	);
};
