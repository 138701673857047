import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ErrorResponse, httpClient } from "../api/config/axios.config";
import { DeviceRecord } from "../components/DeviceRecord";
import { useLoading } from "../hooks/useLoading.hook";
import { DashboardLayout } from "../layouts/DashboardLayout";
import { IDeviceFoundItem, IDeviceResponse } from "../models/device.model";
import { StoreType } from "../store";
import { IUserResponse } from "../store/actions/user.types";
export const DevicePage: React.FC = () => {
	const user = useSelector<StoreType, IUserResponse | undefined>(
		(state) => state.user.data
	);

	console.log(process.env.REACT_APP_BACKEND_API);

	const [searchValue, setSearchValue] = useState("");
	const { loading, setLoading } = useLoading("done");
	const [deviceData, setDeviceData] = useState<IDeviceResponse>();
	const [filteredDeviceData, setFilteredDeviceData] =
		useState<IDeviceFoundItem[]>();

	const loadDevices = async () => {
		try {
			setLoading("loading");
			const { data } = await httpClient.get("/device/search", {
				headers: {
					Authorization: `Bearer ${user?.authToken}`,
				},
			});
			const response = data as IDeviceResponse;
			setDeviceData(response);
			setFilteredDeviceData(response.foundItems);
			setLoading("done");

			console.log(data);
		} catch (e) {
			setLoading("failed");

			const err = e as ErrorResponse;
			alert(err?.response?.data?.message || "could not load data");
		}
	};

	// filter the data
	useEffect(() => {
		if (searchValue.trim() !== "") {
			setFilteredDeviceData(
				deviceData?.foundItems.filter((item) => {
					return item.serialNumber.includes(searchValue);
				})
			);
		} else {
			setFilteredDeviceData(deviceData?.foundItems);
		}
	}, [searchValue]);

	useEffect(() => {
		loadDevices();
	}, []);
	return (
		<DashboardLayout>
			{loading === "loading" && <span>loading...</span>}
			{loading === "failed" && <span>could not load data</span>}
			{loading === "done" && (
				<>
					<div className="level">
						<div className="level-right">
							<span className="subtitle">Device</span>
						</div>
						<div className="level-left">
							<div className="field has-addons">
								<div className="control">
									<input
										onChange={(e) => setSearchValue(e.target.value)}
										type="text"
										className="input"
									/>
								</div>
								<div className="control">
									<button className="button is-light-text">Search</button>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div className="columns is-centered">
						<table className="table is-fullwidth">
							<thead>
								<tr>
									<th>Serial Number</th>
									<th>Fireware</th>
									<th>Initial Registration</th>
									<th>Latest Registration</th>
									<th>Info</th>
								</tr>
							</thead>

							<tbody>
								{filteredDeviceData?.map((item) => (
									<DeviceRecord
										key={item.id}
										id={item.id}
										lastFirmwareVersion={item.lastFirmwareVersion}
										createdAt={item.createdAt}
										serialNumber={item.serialNumber}
										lastRegistrationDate={item.lastRegistrationDate}
									/>
								))}
							</tbody>
						</table>
					</div>
				</>
			)}
		</DashboardLayout>
	);
};
