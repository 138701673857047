import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { ErrorResponse, httpClient } from "../api/config/axios.config";
import { DashboardLayout } from "../layouts/DashboardLayout";
import { IDeviceInfoParams } from "../models/device.model";
import { StoreType } from "../store";
import { IUserResponse } from "../store/actions/user.types";
import { useLoading } from "../hooks/useLoading.hook";
import {
	AlertResolutionStateType,
	AlertViewStateType,
	IAlertResponse,
} from "../models/alert.model";
import { AlertinfoComp } from "../components/AlertInfo";

export const AlertInfoPage: React.FC = () => {
	const user = useSelector<StoreType, IUserResponse | undefined>(
		(state) => state.user.data
	);

	const [nextPage, setNextPage] = useState(1);

	const [checkAll, setCheckAll] = useState(false);

	const [checkedItems, setCheckedItems] = useState<string[]>([]);

	const addRemoveItem = (id: string, action: "add" | "remove") => {
		if (action === "remove") {
			setCheckedItems(checkedItems.filter((record) => record !== id));
		}
		if (action === "add" && checkedItems.indexOf(id) === -1) {
			setCheckedItems([...checkedItems, id]);
		}
	};

	const [viewState, setViewState] = useState<AlertViewStateType>("all");
	const [resolutionState, setResolutionState] =
		useState<AlertResolutionStateType>("all");
	const { loading, setLoading } = useLoading("done");
	const { serialNumber } = useParams<IDeviceInfoParams>();
	const location = useLocation();

	const [deviceData, setDeviceData] = useState<IAlertResponse>();

	const loadDevices = async () => {
		let url = `/alert/search?page=${nextPage}`;
		if (viewState !== "all") {
			url += `&viewState=${viewState}`;
		}
		if (resolutionState !== "all") {
			url += `&resolutionState=${resolutionState}`;
		}
		try {
			setLoading("loading");
			const { data } = await httpClient.get(url, {
				headers: {
					Authorization: `Bearer ${user?.authToken}`,
				},
			});
			setDeviceData(data as IAlertResponse);
			setLoading("done");
		} catch (e) {
			setLoading("failed");
			const err = e as ErrorResponse;
			alert(err?.response?.data?.message || "error loading data");
		}
	};

	useEffect(() => {
		loadDevices();
	}, [nextPage, viewState, resolutionState]);
	return (
		<DashboardLayout>
			{loading === "loading" && <span>loading...</span>}
			{loading === "failed" && (
				<span>error loading data, please try again</span>
			)}
			{loading === "done" && (
				<>
					<div className="level">
						<div className="level-right">
							<span className="subtitle">{serialNumber}</span>
						</div>
						<div className="level-left">
							<div className="buttons">
								<button
									onClick={() => setResolutionState("resolved")}
									className={`${
										resolutionState === "resolved" && "is-info"
									} button`}
								>
									Resolved
								</button>
								<button
									onClick={() => setResolutionState("ignored")}
									className={`${
										resolutionState === "ignored" && "is-info"
									} button`}
								>
									Ignored
								</button>
								<button
									onClick={() => setResolutionState("all")}
									className={`${resolutionState === "all" && "is-info"} button`}
								>
									All
								</button>
							</div>
						</div>
					</div>
					<br />
					<div className="columns is-centered">
						<table className="table is-fullwidth">
							<thead>
								<tr>
									<th>
										<input
											onChange={(e) => setCheckAll(e.currentTarget.checked)}
											type="checkbox"
											className="checkbox"
										/>
									</th>
									<th>Device</th>
									<th>Sensor</th>
									<th>Type</th>
									<th>Status</th>
									<th>Generated on</th>
									<th>Last Update</th>
									<th>Info</th>
								</tr>
							</thead>

							<tbody>
								{deviceData?.foundItems.map((item) => (
									<AlertinfoComp
										addRemoveItem={addRemoveItem}
										checkUnCheckItem={checkAll}
										key={item.id}
										resolutionState={item.resolutionState}
										viewState={item.viewState}
										description={item.description}
										snapshotTimestamp={item.snapshotTimestamp}
										reading={item.reading}
										serialNumber={item.serialNumber}
										createdAt={item.createdAt}
										updatedAt={item.updatedAt}
										id={item.id}
										sensor={item.sensor}
									/>
								))}
							</tbody>
						</table>
					</div>
					{/* pagination section */}
					<div className="columns is-centered">
						<div className="column is-2">
							<div className="has-text-centered mb-5">
								<ul className="pagination-list">
									<li>
										{deviceData?.currentPage} of {deviceData?.totalPages}
									</li>
									<li>
										<button
											onClick={(e) =>
												deviceData?.currentPage &&
												setNextPage(deviceData?.currentPage - 1)
											}
											disabled={deviceData?.currentPage === 1}
											className="button pagination-link"
										>
											prev
										</button>
									</li>
									<li>
										<button
											onClick={(e) =>
												deviceData?.nextPage &&
												setNextPage(deviceData?.nextPage)
											}
											disabled={!deviceData?.nextPage}
											className="button pagination-link"
										>
											next
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</>
			)}
		</DashboardLayout>
	);
};
