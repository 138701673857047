export enum UserActionTypes {
	LOGIN_USER = "LOGIN_USER",
	LOGOUT_USER = "LOGOUT_USER",
}

export interface IUserState {
	username: string;
	password: string;
}

export interface IUserResponse {
	authToken: string;
	user: {
		username: string;
		id: string;
		createdAt: string;
	};
}

export type LoginUserActionType = {
	type: UserActionTypes.LOGIN_USER;
	payload: IUserResponse;
};
export type LogoutUserActionType = {
	type: UserActionTypes.LOGOUT_USER;
};

export type UserDispatchTypes = LoginUserActionType | LogoutUserActionType;
