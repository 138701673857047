import { applyMiddleware, createStore } from "redux";
import reducer from "./reducers";
import thunk from "redux-thunk";

// TODO: ADD MIDDLEWARES FOR WORK HERE
export const store = createStore(reducer, applyMiddleware(thunk));

export type StoreType = ReturnType<typeof reducer>;

export default store;
