import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StoreType } from "../store";
import { LogoutUserAction } from "../store/actions/user.action";
import { IUserReducerState } from "../store/reducers/user.reducer";
import "./main.sass";
export const DashboardLayout: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const user = useSelector<StoreType, IUserReducerState>((state) => state.user);
	const logOut = () => {
		dispatch(LogoutUserAction());
	};

	return (
		<>
			<div className="level has-background-light px-5 pt-2 pb-2">
				<div className="level-right">
					<p className="title">Smart AC</p>
				</div>
				<div className="level-left">
					<span className="level-item">
						<Link to="/">
							<span className="has-font-weight-bold">Devices</span>
						</Link>
					</span>
					<span className="level-item">
						<Link to="/alert">
							<span>Alerts</span>
						</Link>
					</span>
					<span className="level-item">
						<button onClick={logOut} className="button is-primary">
							Logout
						</button>
					</span>
				</div>
			</div>
			<div className="container">{children}</div>
		</>
	);
};
