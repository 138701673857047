import {
	IUserResponse,
	IUserState,
	UserActionTypes,
	UserDispatchTypes,
} from "../actions/user.types";

export interface IUserReducerState {
	loggedIn: boolean;
	data?: IUserResponse;
}

export const defaultDataState = {
	authToken: "",
	user: {
		username: "",
		createdAt: "",
		id: "",
	},
};

export const initialData: IUserReducerState = {
	// TODO: REMOVE THIS LATER
	loggedIn: false,
	data: defaultDataState,
};

export const UserReducer = (
	state = initialData,
	action: UserDispatchTypes
): IUserReducerState => {
	switch (action.type) {
		case UserActionTypes.LOGIN_USER:
			return {
				loggedIn: true,
				data: action.payload,
			};

		case UserActionTypes.LOGOUT_USER:
			return {
				loggedIn: false,
				data: defaultDataState,
			};
		default:
			return state;
	}
};
