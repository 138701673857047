import React, { useEffect } from "react";
import { IAlertFoundItem } from "../models/alert.model";

interface AlertInfoPropInterface extends IAlertFoundItem {
	checkUnCheckItem: boolean;
	addRemoveItem: (id: string, action: "add" | "remove") => void;
}
export const AlertinfoComp: React.FC<AlertInfoPropInterface> = ({
	resolutionState,
	viewState,
	description,
	snapshotTimestamp,
	reading,
	serialNumber,
	createdAt,
	updatedAt,
	sensor,
	id,
	checkUnCheckItem,
	addRemoveItem,
}) => {
	const useAddRemoveItem = (isChecked: boolean) => {
		isChecked ? addRemoveItem(id, "add") : addRemoveItem(id, "remove");
	};

	useEffect(() => {
		console.log(checkUnCheckItem);
		useAddRemoveItem(checkUnCheckItem);
	}, [checkUnCheckItem]);
	return (
		<tr>
			<th>
				<input
					defaultChecked={false}
					onChange={(e) => useAddRemoveItem(e.currentTarget.checked)}
					type="checkbox"
					className="checkbox"
				/>
			</th>
			<th>{serialNumber}</th>
			<td>{sensor}</td>
			<td>{description}</td>
			<td>
				{viewState} / {resolutionState}
			</td>
			<td>{new Date(createdAt).toLocaleString()}</td>
			<td>{new Date(updatedAt).toLocaleString()}</td>
			<td>
				<button className="button is-small is-info is-light is-rounded">
					view
				</button>
			</td>
		</tr>
	);
};
