import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { FormPage } from "./pages/Form";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import PrivateRoute from "./components/PrivateRoutes";
import { DevicePage } from "./pages/Device";
import { DeviceInfoPage } from "./pages/DeviceInfo";
import { AlertInfoPage } from "./pages/Alert";
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router>
				<Switch>
					<PrivateRoute exact path="/form" component={FormPage} />
					<PrivateRoute exact path="/" component={DevicePage} />
					<PrivateRoute
						exact
						path="/device/:serialNumber"
						component={DeviceInfoPage}
					/>
					<PrivateRoute exact path="/alert" component={AlertInfoPage} />
					<Route path="/login" exact>
						<LoginPage />
					</Route>
					<Route path="*" exact>
						<>
							<div className="container has-text-centered">
								<div>page not found</div>
							</div>
						</>
					</Route>
				</Switch>
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
