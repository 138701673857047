import React from "react";

import { useForm, UseFormReturn } from "react-hook-form";
export const FormPage: React.FC = () => {
	interface formFields {
		firstName: string;
		lastName: string;
		username: string;
		password: string;
		age: number;
	}

	const {
		register,
		setError,
		handleSubmit,
		clearErrors,
		formState: { errors },
	} = useForm<formFields>({
		criteriaMode: "all",
		defaultValues: {
			firstName: "",
			lastName: "",
		},
	});

	const submitForm = (data: any) => {
		clearErrors();
		console.log(data);
	};
	return (
		<div>
			<form action="" onSubmit={handleSubmit(submitForm)}>
				<input
					type="text"
					placeholder="first name"
					{...register("firstName", {
						maxLength: {
							value: 2,
							message: "please firstname cannot be more than 2",
						},
						required: {
							value: true,
							message: "please enter the user's first name ",
						},
					})}
				/>
				{errors.firstName && errors.firstName?.message}
				<br />
				<input
					type="text"
					placeholder="last name"
					{...register("lastName", {
						maxLength: 2,
						required: {
							value: true,
							message: "please enter the user's first name ",
						},
					})}
				/>
				{errors.lastName && errors.firstName?.message}

				<br />

				<input
					type="text"
					placeholder="username"
					{...register("username", {
						onChange: (e) => {
							clearErrors("username");
						},
						onBlur: () => {
							console.log("loading...");
							setTimeout(() => {
								setError("username", {
									type: "manual",
									message: "the username is already taken ",
								});
								console.log("loading completed");
							}, 2000);
						},
						required: {
							value: true,
							message: "please enter the user's first name ",
						},
					})}
				/>
				{errors.username && errors.username?.message}

				<br />
				<input
					type="text"
					placeholder="password"
					{...register("password", {
						required: {
							value: true,
							message: "please enter the user's first name ",
						},
						pattern: {
							value: /[a-z]+/,
							message: "please enter a valid password",
						},
					})}
				/>
				<br />
				<input
					type="number"
					placeholder="age"
					{...register("age", {
						valueAsNumber: true,
						required: {
							value: true,
							message: "please enter the user's first name ",
						},
					})}
				/>
				<br />
				<input type="submit" value="submit" />
			</form>
		</div>
	);
};
