import React, { createRef, FormEvent, useState } from "react";
import { MainLayout } from "../layouts/Main";
import { useForm } from "react-hook-form";
import { ErrorResponse, httpClient } from "../api/config/axios.config";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginUserAction } from "../store/actions/user.action";
import { IUserResponse } from "../store/actions/user.types";

interface FormTemplate {
	username: string;
	password: string;
}

export const LoginPage: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		register,
		clearErrors,
		reset,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<FormTemplate>({
		defaultValues: {
			username: "admin",
			password: "admin",
		},
	});

	const useSubmitForm = async (data: FormTemplate) => {
		try {
			setLoading(true);
			const response = await httpClient.post("/user/login", data);
			setLoading(false);
			dispatch(LoginUserAction(response.data as IUserResponse));
			history.push("/");

			// store jwt and userinfo in the store
		} catch (e) {
			const err = e as ErrorResponse;
			setLoading(false);
			alert(err.response.data.message);
			reset();
		}
	};
	return (
		<MainLayout>
			<div className="columns is-centered pt-5">
				<div className="">
					<div className="content">
						<p className="title">Smart AC</p>
					</div>
					<form action="" onSubmit={handleSubmit(useSubmitForm)}>
						<div className="field">
							<div className="control">
								<input
									{...register("username", {
										onChange: (e) => clearErrors(),
										required: {
											value: true,
											message: "please provide a valid username ",
										},
									})}
									type="text"
									className="input"
									placeholder="username"
								/>
							</div>
							{errors.username && (
								<p className="help is-danger">{errors.username?.message}</p>
							)}
						</div>
						<div className="field">
							<div className="control">
								<input
									{...register("password", {
										onChange: (e) => clearErrors(),
										required: {
											value: true,
											message: "please provide your password ",
										},
									})}
									type="password"
									className="input"
									placeholder="password"
								/>
							</div>
							{errors.password && (
								<p className="help is-danger">{errors.password?.message}</p>
							)}
						</div>
						<div className="field">
							<div className="control">
								<input
									type="submit"
									className="button is-info"
									value={loading ? "prcessing..." : "submit"}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</MainLayout>
	);
};
