import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API;
axios.defaults.timeout = 30000;

export const httpClient = axios;

export interface ErrorResponse {
	response: {
		data: {
			error: string;
			message: string;
			statusCode: string;
		};
	};
	message: string;
}
