import { useState } from "react";

export type LoadingState = "loading" | "done" | "failed";
export const useLoading = (initial: LoadingState) => {
	const [loading, setLoading] = useState<LoadingState>("done");

	return {
		loading,
		setLoading,
	};
};
