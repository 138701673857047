import { getAllByAltText } from "@testing-library/dom";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { StoreType } from "../store";
import { IUserReducerState } from "../store/reducers/user.reducer";

interface PrivateRouteProps {
	component: any;
	path: string;
	exact: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
	component: Component,
	...rest
}) => {
	const user = useSelector<StoreType, IUserReducerState>((state) => state.user);
	return (
		<Route
			{...rest}
			render={(props) =>
				user.loggedIn ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: "/login", state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
