import React from "react";
import { Link } from "react-router-dom";
import { IDeviceFoundItem } from "../models/device.model";
export const DeviceRecord: React.FC<IDeviceFoundItem> = ({
	serialNumber,
	lastFirmwareVersion,
	createdAt,
	lastRegistrationDate,
}) => {
	return (
		<tr>
			<th>{serialNumber}</th>
			<td>{lastFirmwareVersion}</td>
			<td>{new Date(createdAt).toLocaleDateString()}</td>
			<td>{new Date(lastRegistrationDate).toLocaleDateString()}</td>
			<td>
				<Link to={`/device/${serialNumber}`}>
					<button className="button is-small is-info is-light is-rounded">
						view
					</button>
				</Link>
			</td>
		</tr>
	);
};
