export enum AlertViewStateEnum {
	New = "New",
	Viewed = "Viewed",
}

export enum AlertResolutionStateEnum {
	New = "new",
	Resolved = "resolved",
	Ignored = "ignored",
}
export enum DeviceHealthStatusEnum {
	Ok = "OK",
	NeedsFilter = "needs_filter",
	NeedsService = "needs_service",
}

export interface IReadingFoundItem {
	healthStatus: DeviceHealthStatusEnum;
	carbonMonoxide: number;
	temperature: number;
	humidity: string;
	serialNumber: string;
	timestamp: string;
	createdAt: string;
	id: string;
	temperatedFlagged?: boolean;
	humidityFlagged?: boolean;
	carbonMonoxideFlagged?: boolean;
	healthStatusFlagged?: boolean;
}

export interface IReadingResponse {
	count: number;
	currentPage: number;
	limit: number;
	nextPage: number | null;
	totalPages: number;
	foundItems: IReadingFoundItem[];
}
